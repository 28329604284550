$(document).ready(function() {
  // if ($('#btnToTop')) {
  //   $('#btnToTop').on('click', function(e) {
  //     window.scroll({
  //       top: 0, 
  //       left: 0, 
  //       behavior: 'smooth'
  //     });
  //   })
  // }

  // window.onscroll = function() {
  //   scrollFunction()
  // };

  // function scrollFunction() {
  //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
  //     $('#btnToTop').show();
  //   } else {
  //     $('#btnToTop').hide();
  //   }
  // }
})