
$(document).ready(function() {
	if($('.front-partners__slider')) {
		$(".front-partners__slider").slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			adaptiveHeight: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 3000,
			responsive: [
				{
					breakpoint: 1920,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 680,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		})
	}
});
