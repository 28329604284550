var mobileMenu = document.querySelector( '.mobile-menu' );
var toggleMobileMenu = document.querySelector( '.header__mobile .btn-toggle-menu' );

if ( toggleMobileMenu && mobileMenu ) {
	toggleMobileMenu.addEventListener( 'click', function() {
		if ( mobileMenu.classList.contains( 'open' ) ) {
			toggleMobileMenu.classList.remove( 'open' );
			mobileMenu.classList.remove( 'open' );
		} else {
			toggleMobileMenu.classList.add( 'open' );
			mobileMenu.classList.add( 'open' );
		}
	});
}

$( window ).scroll( scrollEvent );

function scrollEvent() {
	const scrollTop = $( window ).scrollTop();
	if ( scrollTop > 10 ) {
		$( '.header' ).addClass( 'header--scroll' );
	} else {
		$( '.header' ).removeClass( 'header--scroll' );
	}
}
