var $menuFrontPage = $(".mobile-menu--front"),
    $frontMenuToggle = $(".front-nav-button"),
    $frontMenuBtnToggle = $(".front-nav-button .btn-toggle-menu");

if ( $frontMenuToggle && $menuFrontPage ) {
    $frontMenuToggle.on( 'click', function() {
        if ($menuFrontPage.hasClass( 'open' ) ) {
            $frontMenuBtnToggle.removeClass( 'open' );
            $menuFrontPage.removeClass( 'open' );
            $("html").removeClass("menu--open")
        } else {
            $frontMenuBtnToggle.addClass( 'open' );
            $menuFrontPage.addClass( 'open' );
            $("html").addClass("menu--open")
        }
    });
}