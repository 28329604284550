// $(document).ready(function() {
// 	if($('.about-features__slider')) {
// 		$(".about-features__slider").slick({
// 			slidesToShow: 4,
// 			slidesToScroll: 1,
// 			adaptiveHeight: true,
// 			arrows: true,
// 			speed: 500,
// 			responsive: [
// 				{
// 					breakpoint: 1800,
// 					settings: {
// 						slidesToShow: 3
// 					}
// 				},
// 				{
// 					breakpoint: 1300,
// 					settings: {
// 						slidesToShow: 2
// 					}
// 				},
// 				{
// 					breakpoint: 680,
// 					settings: {
// 						slidesToShow: 1,
// 						adaptiveHeight: false,
// 					}
// 				}
// 			]
// 		})
// 	}

// 	if ($('.about-features__card-inner')) {
// 		$('.about-features__card-inner').on('click', function (e) {
// 			$('#icon-click').css('display', 'none');
// 		})
// 	}
// });
