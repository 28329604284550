$(document).ready(function() {

  if ($('#region')) {
    let activeRegionId = $('#region:first').data('region-id');
    $('#region:first').append('<span class="line"></span>');

    toggleRegion(activeRegionId);

		$('.region').on('click', function (e) {
      if (e.target.parentNode.tagName === 'DIV' && e.target.parentNode.dataset.regionId) {
        const chosenRegion = e.target.parentNode.dataset.regionId || "";
        if (chosenRegion) {
          toggleRegion(chosenRegion);
        }
      }
		})
	}

  function toggleRegion(regionId) {
    $('.line').animate({opacity: 0}, 400, "linear", function() {
      $( this ).remove();
    });    
    $(`.region[data-region-id="${regionId}"]`).append('<span class="line"></span>');
    $(`.oblast`).removeClass('visible').animate({opacity: 0}, 100);
    $(`.agent`).removeClass('visible').animate({opacity: 0}, 100);
    $(`#oblast[data-parent-id="${regionId}"]`).addClass('visible').animate({opacity: 1}, 300);
    $(`#agent[data-agent-region="${regionId}"]`).addClass('visible').animate({opacity: 1}, 300);

  }

  if ($('#selectRegionMobile')) {
    $('#js-contacts-region').on('change', function (e) {
      const select = document.querySelector('#selectRegionMobile');

      setTimeout(function () {
        const chosenElement = select.querySelector('li.selected');

        if (chosenElement.getAttribute('rel')) {
          const regionId = chosenElement.getAttribute('rel');

          if (window.location.pathname.replace('/', '') === 'how-to-order/') {
            window.history.replaceState(null, null, `?region=${regionId}`);
            $('html,body').animate({
              scrollTop: $(`div[data-id="region-${regionId}"]`).offset().top - 100}, 'slow');
              $(`input[id="type-${regionId}"]`).attr('checked', true);
          } else {
            window.location.href = `/how-to-order?region=${regionId}`;
          }
        }
      }, 300);
    })
  }
})