function initSelectCustom( $customElement ) {
	const $select = $customElement;
	const numberOfOptions = $select.children.length;
	const isDisabled = $select.disabled;
	$select.classList.add( 'select-hidden' );
	if ( $select.classList.contains( 'js-select-autocomplete' ) && ! isDisabled ) {
		return;
	}
	const $wrapper = document.createElement( 'div' );
	$wrapper.className = 'select';
	$select.parentNode.insertBefore( $wrapper, $select );
	$wrapper.appendChild( $select );

	const $selectStyled = document.createElement( 'div' );
	$selectStyled.className = 'select-styled';
	if ( isDisabled ) {
		$selectStyled.classList.add( 'disabled' );
	}
	$select.after( $selectStyled );
	$selectStyled.innerText = $select.querySelectorAll( 'option[selected]' )[ 0 ]
		? $select.querySelectorAll( 'option[selected]' )[ 0 ].textContent
		: $select.querySelectorAll( 'option' )[ 0 ].textContent;

	const $selectValue = $select.querySelectorAll( 'option[selected]' )[ 0 ]
		? $select.querySelectorAll( 'option[selected]' )[ 0 ].value
		: $select.querySelectorAll( 'option' )[ 0 ].value;

	const $listWrap = document.createElement( 'div' );
	$listWrap.className = 'select-options';
	$selectStyled.after( $listWrap );

	const $list = document.createElement( 'ul' );
	$list.className = 'select-options-list';
	$listWrap.append( $list );

	for ( let i = 1; i < numberOfOptions; i++ ) {
		const $li = document.createElement( 'li' );
		$li.innerText = $select.children[ i ].textContent;
		if ( $selectValue === $select.children[ i ].value ) {
			$li.classList.add( 'selected' );
		}
		$li.setAttribute( 'rel', $select.children[ i ].value );
		$list.append( $li );
	}

	$selectStyled.addEventListener( 'click', ( e ) => {
		e.stopPropagation();
		if ( isDisabled ) {
			return;
		}
		document.querySelectorAll( 'div.select.active' ).forEach( ( selectEl ) => {
			if ( selectEl.querySelectorAll( '.select-styled' )[ 0 ] !== e.target ) {
				selectEl.classList.remove( 'active' );
			}
		} );
		e.target.parentElement.classList.toggle( 'active' );
	} );

	const $listItems = $list.querySelectorAll( 'li' );

	$listItems.forEach( function( item ) {
		item.addEventListener( 'click', ( e ) => {
			$listItems.forEach( ( item ) => {
				if ( item.classList.contains( 'selected' ) ) {
					item.classList.remove( 'selected' );
				}
			} );

			e.stopPropagation();
			if ( isDisabled ) {
				return;
			}
			$selectStyled.innerText = e.target.innerText;
			$wrapper.classList.remove( 'active' );
			$select.value = e.target.getAttribute( 'rel' );
			$select.dispatchEvent( new Event( 'change' ) );
			e.target.classList.add( 'selected' );
		} );
	} );

	document.addEventListener( 'click', ( e ) => {
		$wrapper.classList.remove( 'active' );
	} );
}

window.addEventListener( 'load', function( event ) {
	document
		.querySelectorAll( '.js-select-custom' )
		.forEach( function( currentElement ) {
			initSelectCustom( currentElement );
		} );
} );
