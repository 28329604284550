
$(document).ready(function() {

	if($('.searching__slider')) {
		$(".searching__slider").slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			// adaptiveHeight: true,
			arrows: true,
			autoplay: true,
			speed: 500,
			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 580,
					settings: {
						slidesToShow: 1,
						adaptiveHeight: false,
					}
				}
			]
		})
	}

	if($('.video-instructions__slider')) {
		$(".video-instructions__slider").slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows: true,
			autoplay: true,
			speed: 500,
			responsive: [
				{
					breakpoint: 960,
					settings: {
						slidesToShow: 1,
						adaptiveHeight: false,
					}
				}
			]
		})
	}
});
