
$(document).ready(function() {

	if($('.libraries__slider')) {
		$(".libraries__slider").slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			adaptiveHeight: true,
			arrows: true,
			autoplay: true,
			speed: 500,
			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						adaptiveHeight: false,
					}
				}
			]
		})
	}
});
