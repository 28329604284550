
$(document).ready(function() {

	if($('.front-main__slider')) {
		$(".front-main__slider").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			arrows: true,
			autoplay: true,
			speed: 500
		})
	}

	if($('.front-clients__slider')) {
		$(".front-clients__slider").slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			adaptiveHeight: true,
			arrows: true,
			autoplay: true,
			speed: 500,
			responsive: [
				{
					breakpoint: 1800,
					settings: {
						slidesToShow: 4
					}
				},
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 950,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 580,
					settings: {
						slidesToShow: 1,
						adaptiveHeight: false,
					}
				}
			]
		})
	}

	if($('.front-offers__slider')) {
		$(".front-offers__slider").slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			// adaptiveHeight: true,
			arrows: true,
			autoplay: true,
			speed: 500,
			responsive: [
				{
					breakpoint: 1800,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 680,
					settings: {
						slidesToShow: 1,
						adaptiveHeight: false,
					}
				}
			]
		})
	}
});
